import './App.css';
import React, { useState, useEffect } from 'react';
import API_KEY_ENC from './includes';

function App() {
  const api_key = atob(API_KEY_ENC);
  //console.log(`App.js: api_key = ${api_key}`);
  const [playlistRecords, setPlaylistRecords] = useState([]);
  var [searchText, setSearchText] = useState('');
  var [channelTitle, setChannelTitle] = useState('');
  var [channelId, setChannelId] = useState('');
  var [playlistId, setPlaylistId] = useState('');
  let playlistUrl_init = 'https://www.googleapis.com/youtube/v3/playlistItems?';
  playlistUrl_init += 'part=contentDetails,id,snippet,status&';
  playlistUrl_init += 'maxResults=50&';
  playlistUrl_init += 'playlistId=PL82uaKJraAILRBFE1XhCyfvu-Fclc6vv1&';
  playlistUrl_init += `key=${api_key}`;
  var [playlistUrl, setPlaylistUrl] = useState(playlistUrl_init);
  var [playlistUrlDisp, setPlaylistUrlDisp] = useState(playlistUrl_init);
  var [filteredPlaylistRecords, setFilteredPlaylistRecords] = useState([]);

  useEffect(() => {
    const getPlaylistRecords = async () => {
      const res = await fetch(playlistUrl);
      const data = await res.json();
      const records = data.items.map(record => {
        return {
          videoId: record.snippet.resourceId.videoId,
          videoTitle: record.snippet.title,
          listPosition: record.snippet.position
        }
      });
      setPlaylistRecords(records);
      setFilteredPlaylistRecords(records);
      setChannelTitle(data.items[0].snippet.channelTitle);
      setChannelId(data.items[0].snippet.channelId);
      setPlaylistId(data.items[0].snippet.playlistId);
      setPlaylistUrlDisp(data.items[0].snippet.playlistId);
      //console.dir(data);
    };
    getPlaylistRecords();
  }, [playlistUrl]);

  const handleChange_searchText = e => {
    setSearchText(e.target.value);
    setFilteredPlaylistRecords(playlistRecords.filter(s => s.videoTitle.includes(e.target.value)));
  };

  const handleChange_playlistUrl = e => {
    setPlaylistUrlDisp(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    let url = 'https://www.googleapis.com/youtube/v3/playlistItems?';
    url += 'part=contentDetails,id,snippet,status&';
    url += 'maxResults=50&';
    url += `playlistId=${e.target.input_playlistUrl.value}&`;
    url += `key=${api_key}`;
    console.log(url);
    setPlaylistUrl(url);

  };

  return (
    <div className="playlist-manager-app">
      <div className="header-section">
        <h1>YouTube Playlist Manager</h1>
        <h3>This is a React.js App that displays videos from playlist specified by YouTube playlist ID below and allows searching within playlist</h3>

        <form className='form_playlistUrl' onSubmit={handleSubmit}>
          {/* url_input */}
          <input className='input_playlistUrl'
            type='text'
            placeholder='Enter YouTube Playlist ID'
            value={playlistUrlDisp}
            name='input_playlistUrl'
            onChange={handleChange_playlistUrl}
          /> {/* url_input */}

          {/* button */}
          <button className='button_playlistUrl'>Update</button>
        </form> {/* form_playlistUrl */}

        <div className="caption">
          YouTube Channel: <a href={`https://www.youtube.com/channel/${channelId}/playlists`}>{channelTitle}</a>
        </div>
        <div className="caption">
          <a href={`https://www.youtube.com/playlist?list=${playlistId}`}>Playlist Link</a>
        </div>
      </div> {/* header-section */}

      {/* <br></br><br></br><br></br> */}
      <div className="video-section">

        <br></br>

        {/* input */}
        <input className='input_playlistUrl'
          type='text'
          placeholder='Type search string'
          value={searchText}
          name='input_searchText'
          onChange={handleChange_searchText}
        /> {/* input */}

        <br></br><br></br>

        {/* the grid */}
        {/* <div className='playlist-manager-container'> */}
        <div className="grid">
          {
            filteredPlaylistRecords.map(filteredPlaylistRecord => {
              return (
                // item
                <div className="item" key={filteredPlaylistRecord.listPosition}>

                  {/* iframe */}
                  <div className="video">
                    <iframe width="320" /* "560" */
                      height="180" /* "315" */
                      src={"https://www.youtube.com/embed/" + filteredPlaylistRecord.videoId}
                      srcDoc={"<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/" + filteredPlaylistRecord.videoId + "?autoplay=1><img src=https://img.youtube.com/vi/" + filteredPlaylistRecord.videoId + "/hqdefault.jpg alt='" + filteredPlaylistRecord.videoTitle + "'><span>▶</span></a>"}
                      title={filteredPlaylistRecord.videoTitle}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen>
                    </iframe>
                  </div>

                  {/* caption */}
                  <div className="caption">
                    <a href={"https://www.youtube.com/watch?v=" + filteredPlaylistRecord.videoId}>
                      {filteredPlaylistRecord.videoTitle}
                    </a>
                  </div>

                </div> // item
              ) // inner return
            }) // map
          }
        </div> {/* grid */}
      </div> {/* video-section */}
    </div> // playlist-manager-app
  ); // outer return
} // App()

export default App;